/*
Author:      Zachary Thomas
Created:     2/8/2022
Modified:    8/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, Fragment } from "react";
import ConfirmModal from "../../../components/ConfirmModal/ConfirmModal";
import SaveChangesModal from "../../../components/SaveChangesModal/SaveChangesModal";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import AssetForm from "./AssetForm/AssetForm";
import apiRequest from "../../../utilities/apiRequest";
import PropTypes from "prop-types";
import {
  API,
  MIN_ASSET_FIELD_LENGTH,
  MAX_ASSET_FIELD_LENGTH
} from "../../../utilities/constants";
import "./AssetModal.scss";

// Modal for creating, editing, and deleting assets.
export default function AssetModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showConfirmExit, setShowConfirmExit] = useState(false);
  const [customerAssetIdentifier, setCustomerAssetIdentifier] = useState("");
  const [deviceType, setDeviceType] = useState("");
  const [deviceIdentifier, setDeviceIdentifier] = useState("");
  const [pumpManufacturer, setPumpManufacturer] = useState("");
  const [otherPumpManufacturer, setOtherPumpManufacturer] = useState("");
  const [pumpModel, setPumpModel] = useState("");
  const [pumpSerialNumber, setPumpSerialNumber] = useState("");
  const [controllerManufacturer, setControllerManufacturer] = useState("");
  const [controllerModel, setControllerModel] = useState("");
  const [deviceData, setDeviceData] = useState("");

  // Update state when selected asset prop changes.
  useEffect(() => {
    if (props.showModal) {
      setCustomerAssetIdentifier(props.selectedAsset.customerAssetIdentifier);
      setDeviceType(props.selectedAsset.deviceType);
      setDeviceIdentifier(props.selectedAsset.deviceIdentifier);
      setPumpManufacturer(props.selectedAsset.pumpManufacturer);
      if (
        props.selectedAsset.pumpManufacturer === ""
        || props.pumpManufacturers.includes(props.selectedAsset.pumpManufacturer)
      ) {
        setPumpManufacturer(props.selectedAsset.pumpManufacturer);
        setOtherPumpManufacturer("");
      } else {
        setPumpManufacturer("OTHER");
        setOtherPumpManufacturer(props.selectedAsset.pumpManufacturer);
      }
      setPumpModel(props.selectedAsset.pumpModel);
      setPumpSerialNumber(props.selectedAsset.pumpSerialNumber);
      setControllerManufacturer(props.selectedAsset.controllerManufacturer);
      setControllerModel(props.selectedAsset.controllerModel);
      setDeviceData("");
    }
  }, [props.showModal, JSON.stringify(props.selectedAsset), JSON.stringify(props.pumpManufacturers)]);

  // Validate the asset.
  function assetIsValid() {
    if (deviceIdentifier.length < MIN_ASSET_FIELD_LENGTH || deviceIdentifier.length > MAX_ASSET_FIELD_LENGTH) {
      setErrorMessage(
        `The device identifier must be between ${MIN_ASSET_FIELD_LENGTH}`
        + `and ${MAX_ASSET_FIELD_LENGTH} characters long.`
      );
      return false;
    } else if (deviceType.length < MIN_ASSET_FIELD_LENGTH || deviceType.length > MAX_ASSET_FIELD_LENGTH) {
      setErrorMessage(
        `The device type must be between ${MIN_ASSET_FIELD_LENGTH}`
        + `and ${MAX_ASSET_FIELD_LENGTH} characters long.`
      );
      return false;
    } else {
      return true;
    }
  }

  // Check if the input fields for a device are filled out.
  function deviceIsValid() {
    if (deviceIdentifier.length === 0) {
      setErrorMessage("A device identifier is required for checking device connection.");
      return false;
    } else if (controllerManufacturer.length === 0) {
      setErrorMessage("A controller manufacture is required for checking device connection.");
      return false;
    } else if (controllerModel.length === 0) {
      setErrorMessage("A controller model is required for checking device connection.");
      return false;
    } else {
      return true;
    }
  }

  // Create an asset.
  async function createAsset() {
    if (assetIsValid()) {
      let currentPumpManufacturer;
      if (pumpManufacturer === "OTHER") {
        currentPumpManufacturer = otherPumpManufacturer;
      } else {
        currentPumpManufacturer = pumpManufacturer;
      }

      const requestBody = {
        CustomerAssetIdentifier: customerAssetIdentifier,
        DeviceType: deviceType,
        DeviceIdentifier: deviceIdentifier,
        PumpManufacturer: currentPumpManufacturer,
        PumpModel: pumpModel,
        PumpSerialNumber: pumpSerialNumber,
        ControllerManufacturer: controllerManufacturer,
        ControllerModel: controllerModel
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${API}/asset`,
        "POST",
        requestBody,
        localStorage.getItem("idToken")
      );
      setLoading(false);

      if (response.ok) {
        const newAsset = {
          customerAssetIdentifier: customerAssetIdentifier,
          deviceType: deviceType,
          deviceIdentifier: deviceIdentifier,
          pumpManufacturer: pumpManufacturer,
          pumpModel: pumpModel,
          pumpSerialNumber: pumpSerialNumber,
          controllerManufacturer: controllerManufacturer,
          controllerModel: controllerModel
        };
        props.onAction("CREATE_ASSET", newAsset);
        setErrorMessage("");
        dontSaveChanges();
        props.onClose();

      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to create asset.");
        }
      }
    }
  }

  // Edit an asset.
  async function editAsset(assetId) {
    if (assetIsValid()) {
      let currentPumpManufacturer;
      if (pumpManufacturer === "OTHER") {
        currentPumpManufacturer = otherPumpManufacturer;
      } else {
        currentPumpManufacturer = pumpManufacturer;
      }

      const requestBody = {
        CustomerAssetIdentifier: customerAssetIdentifier,
        DeviceType: deviceType,
        DeviceIdentifier: deviceIdentifier,
        PumpManufacturer: currentPumpManufacturer,
        PumpModel: pumpModel,
        PumpSerialNumber: pumpSerialNumber,
        ControllerManufacturer: controllerManufacturer,
        ControllerModel: controllerModel
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${API}/asset`,
        "PUT",
        requestBody,
        localStorage.getItem("idToken")
      );
      setLoading(false);

      if (response.ok) {
        const updatedAsset = {
          assetId: assetId,
          customerAssetIdentifier: customerAssetIdentifier,
          deviceType: deviceType,
          deviceIdentifier: deviceIdentifier,
          pumpManufacturer: pumpManufacturer,
          pumpModel: pumpModel,
          pumpSerialNumber: pumpSerialNumber,
          controllerManufacturer: controllerManufacturer,
          controllerModel: controllerModel
        };
        props.onAction("UPDATE_ASSET", updatedAsset);
        setErrorMessage("");
        dontSaveChanges();
        props.onClose();

      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to update asset.");
        }
      }
    }
  }

  // Delete an asset.
  async function deleteAsset(assetId, deviceIdentifier, deviceType) {
    setLoading(true);
    const [response, responseBody] = await apiRequest(
      `${API}/asset/${deviceType.toLowerCase()}/${deviceIdentifier}`,
      "DELETE",
      null,
      localStorage.getItem("idToken")
    );
    setLoading(false);

    if (response.ok) {
      props.onAction("DELETE_ASSET", { assetId: assetId });
      setShowConfirmDelete(false);
      setErrorMessage("");
      dontSaveChanges();
      props.onClose();

    } else {
      if (response.status < 500 && responseBody.error) {
        setErrorMessage(responseBody.error);
      } else {
        setErrorMessage("Internal server error. Unable to delete asset.");
      }
    }
  }

  // Validate a device.
  async function validateDevice() {
    setDeviceData("");
    if (deviceIsValid()) {

      const requestBody = {
        DeviceType: deviceType,
        DeviceIdentifier: deviceIdentifier,
        ControllerManufacturer: controllerManufacturer,
        ControllerModel: controllerModel
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${API}/devicevalidate`,
        "POST",
        requestBody,
        localStorage.getItem("idToken")
      );
      setLoading(false);

      if (response.ok && responseBody) {
        setErrorMessage("");
        setDeviceData(JSON.stringify(responseBody, undefined, 2));

      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to validate device connection.");
        }
      }
    }
  }

  // Exit modal if no changes have been made. Otherwise prompt user.
  function exitModal() {
    // Check to see if state is the same as props.
    if (
      customerAssetIdentifier === props.selectedAsset.customerAssetIdentifier
      && deviceType === props.selectedAsset.deviceType
      && deviceIdentifier === props.selectedAsset.deviceIdentifier
      && pumpModel === props.selectedAsset.pumpModel
      && pumpSerialNumber === props.selectedAsset.pumpSerialNumber
      && controllerManufacturer === props.selectedAsset.controllerManufacturer
      && controllerModel === props.selectedAsset.controllerModel
      && deviceType === props.selectedAsset.deviceType
      && deviceType === props.selectedAsset.deviceType
      && deviceType === props.selectedAsset.deviceType
      && (
        pumpManufacturer === props.selectedAsset.pumpManufacturer
        || (pumpManufacturer === "OTHER" && otherPumpManufacturer === props.selectedAsset.pumpManufacturer)
      )
    ) {
      // Since there have been no changes we can safely exit.
      dontSaveChanges();
    } else {
      // We have unsaved changes, give the user a chance to save them.
      setShowConfirmExit(true);
    }
  }

  // Save changes.
  function saveChanges() {
    if (props.mode === "create") {
      createAsset();
    } else {
      editAsset(props.selectedAsset.assetId);
    }
    setShowConfirmExit(false);
  }

  // Exit without saving changes.
  function dontSaveChanges() {
    setShowConfirmExit(false);
    setErrorMessage("");
    props.onClose();
  }

  return (
    <div className="asset-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => exitModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            {props.mode === "create" ? (
              <span>Create Asset</span>
            ) : (
              <span>Edit Asset</span>
            )}
          </h5>
        </ModalHeader>

        <ModalBody>
          <AssetForm
            mode={props.mode}
            pumpManufacturers={props.pumpManufacturers}
            customerAssetIdentifier={customerAssetIdentifier}
            deviceType={deviceType}
            deviceIdentifier={deviceIdentifier}
            pumpManufacturer={pumpManufacturer}
            otherPumpManufacturer={otherPumpManufacturer}
            pumpModel={pumpModel}
            pumpSerialNumber={pumpSerialNumber}
            controllerManufacturer={controllerManufacturer}
            controllerModel={controllerModel}
            onChangeCustomerAssetIdentifier={customerAssetIdentifier => setCustomerAssetIdentifier(customerAssetIdentifier)}
            onChangeDeviceIdentifier={deviceIdentifier => setDeviceIdentifier(deviceIdentifier)}
            onChangeControllerManufacturer={controllerManufacturer => setControllerManufacturer(controllerManufacturer)}
            onChangeControllerModel={controllerModel => setControllerModel(controllerModel)}
            onChangePumpManufacturer={pumpManufacturer => setPumpManufacturer(pumpManufacturer)}
            onChangeOtherPumpManufacturer={otherPumpManufacturer => setOtherPumpManufacturer(otherPumpManufacturer)}
            onChangePumpModel={pumpModel => setPumpModel(pumpModel)}
            onChangePumpSerialNumber={pumpSerialNumber => setPumpSerialNumber(pumpSerialNumber)}
          />

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-2">
                <Error message={errorMessage} />
              </div>
            </div>
          )}

          {deviceData.length > 0 && (
            <div className="mx-3 my-3">
              <label className="mb-3">
                Device Data
              </label>
              <div className="code-block p-3">
                <pre>{deviceData}</pre>
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          {props.mode === "create" ? (
            <Fragment>
              <button className="btn btn-success" type="button" onClick={() => createAsset()}>
                Create Asset
              </button>

              <button className="btn btn-info" type="button" onClick={() => validateDevice()}>
                Validate Device Connection
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          ) : (
            <Fragment>
              <button className="btn btn-danger me-auto" type="button" onClick={() => setShowConfirmDelete(true)}>
                Delete
              </button>

              <button className="btn btn-success" type="button" onClick={() => editAsset(props.selectedAsset.assetId)}>
                Save Changes
              </button>

              <button className="btn btn-info" type="button" onClick={() => validateDevice()}>
                Validate Device Connection
              </button>

              <button className="btn btn-secondary" type="button" onClick={() => exitModal()}>
                Cancel
              </button>
            </Fragment>
          )}
        </ModalFooter>
      </Modal>

      <ConfirmModal
        showModal={props.showModal && showConfirmDelete}
        title="Delete asset?"
        content={"Are you sure that you want to delete the asset? This action cannot be undone."}
        yesText="Delete Asset"
        noText="Cancel"
        danger={true}
        onClose={() => setShowConfirmDelete(false)}
        onYes={() => deleteAsset(props.selectedAsset.assetId, props.selectedAsset.deviceIdentifier, props.selectedAsset.deviceType)}
        onNo={() => setShowConfirmDelete(false)}
      />

      <SaveChangesModal
        showModal={props.showModal && showConfirmExit}
        title="Changes have not been saved!"
        content="Are you sure that you want to exit without saving your changes?"
        onClose={() => setShowConfirmExit(false)}
        onSave={() => saveChanges()}
        onNoSave={() => dontSaveChanges()}
      />
    </div>
  );
}

AssetModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  showModal: PropTypes.bool.isRequired,
  selectedAsset: PropTypes.object.isRequired,
  pumpManufacturers: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func.isRequired,
};