/*
Author:      Zachary Thomas
Created:     5/20/2022
Modified:    8/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import Modal from "../../../components/Modal/Modal";
import ModalHeader from "../../../components/ModalHeader/ModalHeader";
import ModalBody from "../../../components/ModalBody/ModalBody";
import ModalFooter from "../../../components/ModalFooter/ModalFooter";
import Error from "../../../components/Error/Error";
import Spinner from "../../../components/Spinner/Spinner";
import apiRequest from "../../../utilities/apiRequest";
import PropTypes from "prop-types";
import { API } from "../../../utilities/constants";
import "./ValidateModal.scss";

// Modal for validating monitoring devices.
export default function ValidateModal(props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [controllerIndex, setControllerIndex] = useState("0");
  const [deviceData, setDeviceData] = useState("");

  // Check if the input fields for a device are filled out.
  function deviceIsValid() {
    if (serialNumber.length === 0) {
      setErrorMessage("A serial number is required.");
      return false;
    } else if (controllerIndex === "0") {
      setErrorMessage("A controller is required.");
      return false;
    } else {
      return true;
    }
  }

  // Validate a device.
  async function validateDevice() {
    setDeviceData("");
    if (deviceIsValid()) {
      let controllerManufacturer = "LOFA";
      let controllerModel = "CP750";
      if (controllerIndex === "1") {
        controllerManufacturer = "CONTROLSINC";
        controllerModel = "CSERIES";
      }

      const requestBody = {
        DeviceType: "COPILOT",
        DeviceIdentifier: serialNumber,
        ControllerManufacturer: controllerManufacturer,
        ControllerModel: controllerModel
      };

      setLoading(true);
      const [response, responseBody] = await apiRequest(
        `${API}/devicevalidate`,
        "POST",
        requestBody,
        localStorage.getItem("idToken")
      );
      setLoading(false);

      if (response.ok && responseBody) {
        setErrorMessage("");
        setDeviceData(JSON.stringify(responseBody, undefined, 2));

      } else {
        if (response.status < 500 && responseBody.error) {
          setErrorMessage(responseBody.error);
        } else {
          setErrorMessage("Internal server error. Unable to validate device connection.");
        }
      }
    }
  }

  // Close modal.
  function closeModal() {
    setErrorMessage("");
    setSerialNumber("");
    setControllerIndex("0");
    setDeviceData("");
    props.onClose();
  }

  return (
    <div className="validate-modal-container">
      <Spinner loading={loading} />

      <Modal
        show={props.showModal}
        onHide={() => closeModal()}
        size="lg"
        animation
        centered
      >
        <ModalHeader>
          <h5 className="modal-title font-weight-bold">
            Validate Device
          </h5>
        </ModalHeader>

        <ModalBody>
          <div className="mx-3 mb-3">
            <label className="mb-3">
              Device Type
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value="COPILOT"
              disabled
            />
          </div>

          <div className="mx-3 mb-3">
            <label className="mb-3">
              Device Identifier
            </label>
            <input
              className="form-control mx-auto"
              type="text"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
            />
          </div>

          <div className="mx-3 mb-3">
            <label className="mb-3">
              Controller
            </label>
            <select
              className="form-select"
              value={controllerIndex}
              onChange={(e) => setControllerIndex(e.target.value)}
            >
              <option value="0" disabled>Please select a controller...</option>
              <option value="1">CSERIES (CONTROLSINC)</option>
              <option value="2">CP750 (LOFA)</option>
            </select>
          </div>

          {errorMessage.length > 0 && (
            <div className="row">
              <div className="col mt-4 mx-3">
                <Error message={errorMessage} />
              </div>
            </div>
          )}

          {deviceData.length > 0 && (
            <div className="mx-3 my-3">
              <label className="mb-3">
                Device Data
              </label>
              <div className="code-block p-3">
                <pre>{deviceData}</pre>
              </div>
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <button className="btn btn-info" type="button" onClick={() => validateDevice()}>
            Validate Device Connection
          </button>

          <button className="btn btn-secondary" type="button" onClick={() => closeModal()}>
            Cancel
          </button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

ValidateModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};