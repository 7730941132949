/*
Author:      Zachary Thomas
Created:     2/8/2021
Modified:    2/8/2021

Copyright 2021 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Change the key name of an object.
export default function changeKeyName(object, oldKey, newKey) {
  if (oldKey in object) {
    object[newKey] = object[oldKey];
    delete object[oldKey];
  }
}