/*
Author:      Zachary Thomas
Created:     2/8/2022
Modified:    2/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import LoginForm from "./LoginForm/LoginForm";
import Spinner from "../../components/Spinner/Spinner";
import apiRequest from "../../utilities/apiRequest";
import { useHistory } from "react-router-dom";
import { API } from "../../utilities/constants";
import "./LoginPage.scss";

// Page for users to login to their account.
export default function LoginPage() {
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  // Attempts to login a user.
  async function submit(username, password) {
    if (username.length === 0) {
      setErrorMessage("Please enter a username.");
      return;
    } else if (password.length === 0) {
      setErrorMessage("Please enter a password.");
      return;
    }

    setLoading(true);
    const requestBody = {
      Username: username,
      Password: password
    };

    const [response, responseBody] = await apiRequest(
      `${API}/authenticate`,
      "POST",
      requestBody
    );
    setLoading(false);

    if (response.ok) {
      localStorage.setItem("idToken", responseBody.token);
      history.push("/");

    } else {
      if (response.status < 500 && responseBody.error) {
        setErrorMessage(responseBody.error);
      } else {
        setErrorMessage("Internal server error. Unable to login.");
      }
    }
  }

  return (
    <div className="page-login mb-4">
      <Spinner loading={loading} />

      <PageTitle title={`User Login`} />

      <LoginForm
        errorMessage={errorMessage}
        onSubmit={(username, password) => submit(username, password)}
      />
    </div>
  );
}