/*
Author:      Zachary Thomas
Created:     2/8/2022
Modified:    8/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import deepCopy from "../utilities/deepCopy";
import changeKeyName from "../utilities/changeKeyName";

// Clean up and format GET asset API response body.
export default function cleanAsset(responseBody) {
  let responseBodyDeepCopy = deepCopy(responseBody);

  responseBodyDeepCopy.forEach((asset, i) => {
    changeKeyName(asset, "DeviceIdentifier", "deviceIdentifier");
    changeKeyName(asset, "DeviceType", "deviceType");
    changeKeyName(asset, "PumpManufacturer", "pumpManufacturer");
    changeKeyName(asset, "PumpModel", "pumpModel");
    changeKeyName(asset, "ControllerModel", "controllerModel");
    changeKeyName(asset, "ControllerManufacturer", "controllerManufacturer");
    changeKeyName(asset, "CustomerAssetIdentifier", "customerAssetIdentifier");
    changeKeyName(asset, "PumpSerialNumber", "pumpSerialNumber");
    asset.assetId = i + 1;
  });

  responseBodyDeepCopy = {
    assets: responseBodyDeepCopy
  };

  console.log("Processed Response Body: ", responseBodyDeepCopy);

  return responseBodyDeepCopy;
}