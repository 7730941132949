/*
Author:      Zachary Thomas
Created:     2/8/2022
Modified:    8/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React from "react";
import PropTypes from "prop-types";
import {
  MAX_ASSET_FIELD_LENGTH
} from "../../../../utilities/constants";
import "./AssetForm.scss";

// Form for creating an asset.
export default function AssetForm(props) {
  return (
    <div className="asset-form">
      <div className="mx-3 mb-3">
        <label className="mb-3">
          Customer Asset Identifier
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_ASSET_FIELD_LENGTH}
          value={props.customerAssetIdentifier}
          onChange={(e) => props.onChangeCustomerAssetIdentifier(e.target.value)}
        />
      </div>

      <div className="mx-3 mb-3">
        <label className="mb-3">
          Device Type
        </label>
        <input
          className="form-control mx-auto"
          type="text"
          maxLength={MAX_ASSET_FIELD_LENGTH}
          value={props.deviceType}
          disabled
        />
      </div>

      {props.mode === "create" ? (
        <div className="mx-3 mb-3">
          <label className="mb-3">
            Device Identifier
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_ASSET_FIELD_LENGTH}
            value={props.deviceIdentifier}
            onChange={(e) => props.onChangeDeviceIdentifier(e.target.value)}
          />
        </div>
      ) : (
        <div className="mx-3 mb-3">
          <label className="mb-3">
            Device Identifier
          </label>
          <input
            className="form-control mx-auto"
            type="text"
            maxLength={MAX_ASSET_FIELD_LENGTH}
            value={props.deviceIdentifier}
            disabled
          />
        </div>
      )}

      <div className="form-asset mx-3 mb-3">
        <label className="mb-3">
          Pump Manufacturer
        </label>
        <select
          className="form-select"
          value={props.pumpManufacturer}
          onChange={(e) => props.onChangePumpManufacturer(e.target.value)}
        >
          <option value="" disabled>Please select a product manufacturer...</option>
          {props.pumpManufacturers.map((manufacturer, i) =>
            <option value={manufacturer} key={i}>
              {manufacturer}
            </option>
          )}
          <option value="OTHER">
            Other...
          </option>
        </select>
      </div>

      {props.pumpManufacturer === "OTHER" && (
        <div className="form-asset mx-3 mb-3">
          <input
            className="form-control mx-auto"
            type="text"
            value={props.otherPumpManufacturer}
            onChange={(e) => props.onChangeOtherPumpManufacturer(e.target.value)}
          />
        </div>
      )}

      <div className="mx-3 mb-3">
        <label className="mb-3">
          Pump Model
        </label>
        <input
          className="form-control mx-auto"
          type="text-area"
          rows="3"
          maxLength={MAX_ASSET_FIELD_LENGTH}
          value={props.pumpModel}
          onChange={(e) => props.onChangePumpModel(e.target.value)}
        />
      </div>

      <div className="mx-3 mb-3">
        <label className="mb-3">
          Pump Serial Number
        </label>
        <input
          className="form-control mx-auto"
          type="text-area"
          rows="3"
          maxLength={MAX_ASSET_FIELD_LENGTH}
          value={props.pumpSerialNumber}
          onChange={(e) => props.onChangePumpSerialNumber(e.target.value)}
        />
      </div>

      <div className="mx-3 mb-3">
        <label className="mb-3">
          Controller Manufacturer
        </label>
        <input
          className="form-control mx-auto"
          type="text-area"
          rows="3"
          maxLength={MAX_ASSET_FIELD_LENGTH}
          value={props.controllerManufacturer}
          onChange={(e) => props.onChangeControllerManufacturer(e.target.value)}
        />
      </div>

      <div className="mx-3 mb-3">
        <label className="mb-3">
          Controller Model
        </label>
        <input
          className="form-control mx-auto"
          type="text-area"
          rows="3"
          maxLength={MAX_ASSET_FIELD_LENGTH}
          value={props.controllerModel}
          onChange={(e) => props.onChangeControllerModel(e.target.value)}
        />
      </div>
    </div>
  );
}

AssetForm.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]).isRequired,
  pumpManufacturers: PropTypes.array.isRequired,
  customerAssetIdentifier: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  deviceIdentifier: PropTypes.string.isRequired,
  controllerManufacturer: PropTypes.string.isRequired,
  controllerModel: PropTypes.string.isRequired,
  pumpManufacturer: PropTypes.string.isRequired,
  otherPumpManufacturer: PropTypes.string.isRequired,
  pumpModel: PropTypes.string.isRequired,
  pumpSerialNumber: PropTypes.string.isRequired,
  onChangeCustomerAssetIdentifier: PropTypes.func.isRequired,
  onChangeDeviceIdentifier: PropTypes.func.isRequired,
  onChangeControllerManufacturer: PropTypes.func.isRequired,
  onChangeControllerModel: PropTypes.func.isRequired,
  onChangePumpManufacturer: PropTypes.func.isRequired,
  onChangeOtherPumpManufacturer: PropTypes.func.isRequired,
  onChangePumpModel: PropTypes.func.isRequired,
  onChangePumpSerialNumber: PropTypes.func.isRequired
};