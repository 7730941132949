/*
Author:      Zachary Thomas
Created:     1/6/2022
Modified:    8/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "./AssetList.scss";

// A list of assets that support creation, updating, and deletion.
export default function AssetList(props) {
  return (
    <div className="asset-list">
      <div className="list-header p-4">
        <div className="row align-items-top">
          <div className="list-title col-3 col-md-4 col-xxl-6">
            <span className="align-top">Assets</span>
          </div>

          {/* Button for validating monitoring devices. */}
          <div className="col-5 col-md-5 col-xxl-4">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onValidate(0)}
            >
              Validate Device Connection
            </button>
          </div>

          {/* Button for creating new assets. */}
          <div className="col-3 col-md-3 col-xxl-2">
            <button
              className="create-btn btn btn-light float-end"
              onClick={() => props.onSelect(0)}
            >
              Create Asset
            </button>
          </div>
        </div>
      </div>

      <div className="list-body">
        {/* List of interactive assets. */}
        {!props.loading && props.assets.map(asset =>
          <Fragment key={asset.assetId}>
            <div
              className="list-item selectable-container px-3 py-2"
              onClick={() => props.onSelect(asset.assetId)}
            >
              {asset.customerAssetIdentifier} ({asset.deviceIdentifier})
            </div>
          </Fragment>
        )}

        {props.assets.length === 0 && !props.loading && (
          <div className="empty-list py-5 px-4">
            There are no assets to display
          </div>
        )}
      </div>
    </div>
  );
}

AssetList.propTypes = {
  loading: PropTypes.bool,
  assets: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired
};