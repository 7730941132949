/*
Author:      Zachary Thomas
Created:     5/14/2021
Modified:    2/8/2022

Copyright 2021 - 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import SignOutButton from "./SignOutButton/SignOutButton";
import "./Navbar.scss";

// General navbar.
export default function Navbar(props) {

  return (
    <nav className="navbar navbar-expand navbar-custom px-3">
      <Link className="navbar-brand" to={props.currentPage === "/login" ? "/login" : "/"}>
        {props.title}
      </Link>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        {props.currentPage !== "/login" && (
          <Fragment>
            <div className="ms-auto">
              <SignOutButton />
            </div>
          </Fragment>
        )}
      </div>
    </nav>
  );
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
  currentPage: PropTypes.string.isRequired
};