/*
Author:      Zachary Thomas
Created:     2/8/2022
Modified:    8/4/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import "dotenv/config";

let api = "https://pumpview.api.rpm-squared-dev.com";
if (process.env.REACT_APP_ENV === "production") {
  api = "https://pumpview.api.rpm-squared.com";
}

// Constants that are referenced throughout the application.
export const API = api;
export const MIN_ASSET_FIELD_LENGTH = 1;
export const MAX_ASSET_FIELD_LENGTH = 100;