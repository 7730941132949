/*
Author:      Zachary Thomas
Created:     2/8/2022
Modified:    8/3/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

import React, { useState, useEffect, useReducer } from "react";
import useApi from "../../hooks/useApi";
import Error500Page from "../Error500Page/Error500Page";
import Spinner from "../../components/Spinner/Spinner";
import PageTitle from "../../components/PageTitle/PageTitle";
import AssetList from "./AssetList/AssetList";
import AssetModal from "./AssetModal/AssetModal";
import ValidateModal from "./ValidateModal/ValidateModal";
import deepCopy from "../../utilities/deepCopy";
import cleanAsset from "../../api/cleanAsset";
import cleanPumpManufacturer from "../../api/cleanPumpManufacturer";
import { API } from "../../utilities/constants";
import "./HomePage.scss";

// Home page.
export default function HomePage() {
  const types = {
    SET_ASSETS: "SET_ASSETS",
    CREATE_ASSET: "CREATE_ASSET",
    UPDATE_ASSET: "UPDATE_ASSET",
    DELETE_ASSET: "DELETE_ASSET"
  };
  const initialAsset = {
    assetId: 0,
    deviceIdentifier: "",
    deviceType: "COPILOT",
    pumpManufacturer: "",
    pumpModel: "",
    controllerModel: "",
    controllerManufacturer: "",
    customerAssetIdentifier: "",
    pumpSerialNumber: ""
  };
  const [loading, setLoading] = useState(false);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [mode, setMode] = useState("create");
  const [maxAssetId, setMaxAssetId] = useState(0);
  const [selectedId, setSelectedId] = useState(-1);
  const [selectedAsset, setSelectedAsset] = useState(initialAsset);
  const [showValidate, setShowValidate] = useState(false);
  const [assets, dispatch] = useReducer(assetReducer, []);
  const [pumpManufacturers, setPumpManufacturers] = useState([]);

  // Get all assets for the current user.
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/asset`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        responseBody = cleanAsset(responseBody);
        setFailedToLoad(false);
        dispatch({ type: types.SET_ASSETS, payload: responseBody.assets });

      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // Get all pump manufacturers
  useApi(
    () => {
      setLoading(true);
      return true;
    },
    {
      method: "GET",
      url: `${API}/pumpmanufacturer`,
      authorization: localStorage.getItem("idToken")
    },
    async (response, responseBody) => {
      if (response.ok && responseBody) {
        responseBody = cleanPumpManufacturer(responseBody);
        setPumpManufacturers(responseBody.pumpManufacturers);
      } else {
        setFailedToLoad(true);
      }
      setLoading(false);
    },
    []
  );

  // If the selected ID changes, update the selected asset, and mode.
  useEffect(() => {
    const index = assets.findIndex(asset =>
      asset.assetId === selectedId
    );

    if (index === -1) {
      setSelectedAsset(initialAsset);
    } else {
      setSelectedAsset(assets[index]);
    }

    if (selectedId === 0) {
      setMode("create");
    } else if (selectedId > 0) {
      setMode("edit");
    }
  }, [selectedId, JSON.stringify(assets)]);

  // Sort assets by device identifier.
  function sortAssets(assets) {
    return assets.sort((a, b) => {
      if (a.deviceIdentifier < b.deviceIdentifier) {
        return -1;
      } else if (a.deviceIdentifier > b.deviceIdentifier) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  // Asset reducer.
  function assetReducer(state, action) {
    switch (action.type) {
      case types.SET_ASSETS: {
        action.payload = sortAssets(action.payload);
        let tempMaxAssetId = 0;
        action.payload.forEach(asset => {
          if (asset.assetId > tempMaxAssetId) {
            tempMaxAssetId = asset.assetId;
          }
        });
        return action.payload;
      }

      case types.CREATE_ASSET: {
        let stateShallowCopy = [...state, action.payload];
        action.payload.assetId = maxAssetId + 1;
        setMaxAssetId(maxAssetId + 1);
        stateShallowCopy = sortAssets(stateShallowCopy);
        return stateShallowCopy;
      }

      case types.UPDATE_ASSET: {
        let stateDeepCopy = deepCopy(state);
        const assetIndex = state.findIndex(asset =>
          asset.assetId === action.payload.assetId
        );
        if (assetIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(assetIndex, 1, action.payload);
          stateDeepCopy = sortAssets(stateDeepCopy);
          return stateDeepCopy;
        }
      }

      case types.DELETE_ASSET: {
        const stateDeepCopy = deepCopy(state);
        const assetIndex = state.findIndex(asset =>
          asset.assetId === action.payload.assetId
        );
        if (assetIndex === -1) {
          return stateDeepCopy;
        } else {
          stateDeepCopy.splice(assetIndex, 1);
          return stateDeepCopy;
        }
      }

      default: {
        return state;
      }
    }
  }

  return (
    failedToLoad ? (
      <Error500Page />
    ) : (
      <div className="page-crud mb-4">
        <Spinner loading={loading} />

        <PageTitle title="Manage Assets" />

        <AssetList
          assets={assets}
          onSelect={selectedId => setSelectedId(selectedId)}
          onValidate={() => setShowValidate(true)}
        />

        <AssetModal
          mode={mode}
          showModal={selectedId !== -1}
          pumpManufacturers={pumpManufacturers}
          selectedAsset={selectedAsset}
          onClose={() => setSelectedId(-1)}
          onAction={(type, payload) => dispatch({ type: type, payload: payload })}
        />

        <ValidateModal
          showModal={showValidate}
          onClose={() => setShowValidate(false)}
        />
      </div>
    )
  );
}