/*
Author:      Zachary Thomas
Created:     6/8/2022
Modified:    6/8/2022

Copyright 2022 © Cornell Pump Company, All Rights Reserved
-----------------------------------------------------------------
*/

// Clean up and format GET pump manufacturer API response body.
export default function cleanPumpManufacturer(responseBody) {
  const pumpManufacturers = [];

  responseBody.forEach((pumpManufacturer) =>
    pumpManufacturers.push(pumpManufacturer.manufacturerCode)
  );

  responseBody = {
    pumpManufacturers: pumpManufacturers
  };

  console.log("Processed Response Body: ", responseBody);

  return responseBody;
}